<style scoped lang="less">
  .confirm-list {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
  }
  .worker-confirm-page {
    background-color: #FFF;
    margin: 10px;
    &.flex {
      display: flex;
      flex-direction: column;
      .confirm-list {
        overflow: auto;
        height: 0;
        flex: 1;
      }
    }
  }
  .title {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>

<style>
  .worker-confirm-page .fm-loading-fix {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>

<template>
  <div class="worker-confirm-page flex">
    <template>
      <div class="title">
        <fm-title title-text="信息核对状态">
          <Row :gutter="10" align="middle" type="flex">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="queryForm.value">
                <Select slot="prepend" style="width: 100px" v-model="queryForm.key">
                  <Option value="name">姓名</Option>
                  <Option value="idNo">身份证</Option>
                  <Option value="phone">手机</Option>
                </Select>
              </i-input>
            </i-col>
            <i-col>状态：</i-col>
            <i-col>
              <Dropdown trigger="click" @on-click="name => onSearch({status: name})">
              <Button type="primary" v-if="query.status === 'end'">已确认<Icon type="ios-arrow-down"></Icon></Button>
              <Button type="warning" v-else-if="query.status === 'wait'">待确认<Icon type="ios-arrow-down"></Icon></Button>
              <Button type="default" v-else>全部<Icon type="ios-arrow-down"></Icon></Button>
              <DropdownMenu slot="list">
                <DropdownItem :selected="query.status === 'all'" name="all">全部</DropdownItem>
                <DropdownItem :selected="query.status === 'end'" name="end">是</DropdownItem>
                <DropdownItem :selected="query.status === 'wait'" name="wait">否</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            </i-col>
          </Row>
        </fm-title>
        <div>
          <Button style="margin-right: 10px;" icon="md-refresh-circle" :loading="loading.load" @click="loadData">刷新</Button>
          <Poptip transfer confirm @on-ok="confirm" title="确定将所有人的状态改为待确认吗？">
            <Button type="warning" icon="md-sync" :loading="loading.confirm">批量待确认</Button>
          </Poptip>
        </div>
      </div>
      <div class="confirm-list scrollbar" ref="content">
        <Table style="width: 100%;" ref="table" :loading="loading.load" :data="showDataList" :columns="columns" :height="tableHeight"></Table>
      </div>
      <!-- <div style="padding: 10px;">
        <Page @on-change="num => page.num = num" :total="showDataList.length" :current="page.num" :page-size="page.size" show-total show-elevator />
      </div> -->
    </template>
  </div>
</template>

<script>
import { request } from '@/api'

function getDefaultQuery () {
  return {
    value: null,
    key: 'name',
    status: 'all'
  }
}

export default {
  data () {
    return {
      dataList: [],
      loading: {
        load: false,
        confirm: false
      },
      query: getDefaultQuery(),
      queryForm: getDefaultQuery(),
      page: {
        num: 1,
        size: 10
      },
      tableHeight: null
    }
  },
  computed: {
    showDataList () {
      const key = this.query.key
      const value = this.query.value
      const status = this.query.status
      return this.dataList.filter(item => {
        if (key && value && item[key] && String(item[key]).indexOf(value) === -1) {
          return false
        }
        if (status !== 'all' && item.confirmStatus !== status) {
          return false
        }
        return true
      })
    },
    pageDataList () {
      const start = (this.page.num - 1) * this.page.size
      const end = start + this.page.size
      return this.showDataList.slice(start, end)
    },
    columns () {
      let columns = [
        { key: 'name', title: '姓名' },
        { key: 'phone', title: '手机号' },
        { key: 'idNo', title: '身份证' },
        {
          key: 'confirmStatus', title: '确认状态', render (h, { row }) {
            return h('Tag', {
              props: {
                color: row.confirmStatus === 'end' ? 'green' : 'volcano'
              }
            }, row.confirmStatus === 'end' ? '已确认' : '待确认')
          }
        },
        { key: 'confirmTime', title: '确认时间' }
      ]
      return columns
    }
  },
  methods: {
    async confirm () {
      this.loading.confirm = true
      try {
        await request('/basis/worker/confirm/batch_wait', 'post', { type: 'all' })
        this.$Message.success('成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.confirm = false
    },
    onSearch (query) {
      const queryForm = JSON.parse(JSON.stringify(this.queryForm))
      if (query) {
        this.query = Object.assign(queryForm, query)
      } else {
        this.query = queryForm
      }
      this.page.num = 1
    },
    async loadData () {
      this.loading.load = true
      let dataList = await request('/basis/worker/confirm', 'get', { pfmMonthId: this.pfmMonthId })
      dataList.forEach(v => {
        if (v.confirmStatus === null) {
          v.confirmStatus = 'wait'
        }
      })
      this.dataList = dataList
      this.loading.load = false
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    }
  },
  async mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()
    this.loadData()
  },
  destroyed () {
    this.dataList = []
    window.removeEventListener('resize', this.resize)
  },
}
</script>